import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function SignIn() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [FullName, setFullName] = useState("");
    const [Email, setEmail] = useState("");
    const [Plan, setPlan] = useState("Free");
    const [Pass, setPass] = useState("");
    const user = {
        userdata: {
      "FullName": FullName,
      "email": Email,
      "password": Pass
        }
     
    }
    
    const handleClick = async() => {
  setLoading(true)
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/signin`, user, {
        }).then((res) => {
           
          sessionStorage.setItem('x-access-token', res.data.token)
          
          sessionStorage.setItem('photoURL', res.data.user.ImageUrl)
          sessionStorage.setItem('displayName', res.data.user.FullName)
          sessionStorage.setItem('email', res.data.user.email)
          sessionStorage.setItem('plan', res.data.user.Plan)
//   alert("please login ")
  setTimeout(() => {
    navigate('/PaymentPage', { replace: true },
    { value: true });
    setLoading(false)

}, 200);
            
        })
      }
      catch (err) {
  setLoading(false)
        console.log(err)
        alert("please enter valid details and check email address")
      }
     
    };






    const [showPassword, setShowPassword] = useState(false);

    

    return (
        <>
        
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", padding: "25%", textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
            <Stack spacing={3}>
                <TextField name="FullName" label="Full Name" onChange={e => setFullName(e.target.value)}/>
                <TextField name="email" label="Email address" onChange={e => setEmail(e.target.value)}/>

                <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    onChange ={e => setPass(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>)}

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Checkbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover">
                    Forgot password?
                </Link>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                SignIn
            </LoadingButton>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                
                <Link onClick={()=>{  navigate('/login', { replace: true },
                    { value: true });
  
          }} variant="subtitle2" underline="hover">
                  go to  Login
                </Link>
            </Stack>
        </>
    );
}
